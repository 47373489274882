.content {
  background-color: var(--backgrond);
  min-height: calc(100vh - 70px - 4.8rem);
  padding: 1rem 0.5rem;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .content {
    overflow-x: hidden;
  }
}

/* Sidebar */

.sidebar {
  z-index: 2;
  min-width: 6.5rem;
  max-width: 6.5rem;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  margin-bottom: 0;
  list-style: none;

  background-color: var(--backgrond);
  background-size: cover;
  border-right: 1.5px solid var(--gray-600);
}

@media only screen and (min-width: 768px) {
  .sidebar {
    min-width: 14rem;
    max-width: 14rem;
  }
}

.logo {
  visibility: hidden;
  display: none;
  height: 100%;

  /*width: auto;*/
}

.logo-small {
  visibility: visible;
  display: block;
  height: 100%;
}

@media only screen and (min-width: 768px) {
  .logo {
    visibility: visible;
    display: block;
  }

  .logo-small {
    visibility: hidden;
    display: none;
  }
}

.sidebar-divider {
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  margin: 0 1rem;
}

.sidebar-heading {
  color: var(--gray-100);
  font-size: 0.55rem;
  text-align: center;
  padding: 0.75rem;
  margin-bottom: 0.25rem;
}

.nav-link-brand {
  text-decoration: none;
  color: var(--black);
  padding: 0.75rem;
  font-size: 0.8rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border-radius: 0.75rem;
  text-wrap: nowrap;
}

.sidebar-dropdown {
  text-decoration: none;
  color: var(--black);
  padding: 0.75rem;
  font-size: 0.65rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin: 0.1rem;
}

.dropdown {
  text-decoration: none;
  color: var(--black);
  /*padding: 0.75rem;*/
  font-size: 0.65rem;
  display: flex;

  /*justify-content: center;*/
  text-align: center;
  cursor: pointer;
  /*margin: 0.1rem;*/
  align-items: center;
}

.nav-link-brand:hover {
  color: white;
  background-color: var(--medium-green);
}

.nav-link-brand.active {
  color: white;
  background-color: var(--dark-green);
}

.sidebar-icon {
  margin: 0 0 0.75rem 0;
  /*flex-basis: 100%;*/
  transition: transform 1s;
}

.sidebar-icon-right {
  position: absolute;
  right: 0;
}

.teams-link {
  margin: 0 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.35rem;
  cursor: pointer;
  font-size: 0.85rem;
}

.teams-link:hover {
  background-color: #38305025;
}

.teams-list {
  background-color: white;
  color: black;
  padding: 0;
  margin: 1rem 1rem;
  border-radius: 0.35rem;
  transition: max-height 1s;
  overflow: hidden;
}
.dropdown-list {
  background-color: white;
  color: black;
  padding: 0;
  /*margin: 1rem 1rem;*/
  border-radius: 0.35rem;
  transition: max-height 0.2s;
  overflow: auto;
  position: absolute;
  z-index: 10;
}

@media only screen and (min-width: 1050px) {
  .nav-link-brand {
    font-size: 1.25rem;
    /*display: block;*/
    text-align: left;
  }
  .sidebar-dropdown {
    font-size: 0.85rem;
    display: block;
    text-align: left;
    padding: 1.25rem;
  }

  .sidebar-icon {
    margin: 0 0.75rem 0 0;
  }

  .sidebar-heading {
    padding: 0.5rem 1rem 0 1rem;
    font-size: 0.65rem;
    text-align: left;
  }
}

.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

/* Header */

.header {
  height: 4.375rem;
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0.5rem;
  color: var(--gray-300);
  background-color: var(--backgrond);
  width: 100%;
  border-bottom: 1.5px solid var(--gray-600);
}

.vertical-divider {
  width: 0;
  border-right: 1px solid #e3e6f0;
  /*height: calc(4.375rem - 2rem);*/
  /*height: 100%;*/
  /*padding: 1rem;*/
}

/* Footer */

.footer {
  font-size: 0.8rem;
  background-color: var(--backgrond);
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  padding: 16px 0;
  color: var(--gray-300);
  line-height: 1;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .footer {
  }
}

.dashboardCardContainer {
  display: none;
}
