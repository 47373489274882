@import url("https://fonts.googleapis.com/css?family=Nunito:200,200i,300,300i,400,400i,600,600i,700,700i,800,800i,900,900i");
@import "react-responsive-modal/styles.css";

*,
html,
body {
  font-family:
    "Nunito",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
  margin: 0;
  padding: 0;
  font-size: 14px;
}

:root {
  --blue: #4e73df !important;
  --dark-blue: #224abe !important;
  --light-blue: #e7f1ff !important;

  /*--light-gray: #ededed !important;*/
  /*--mid-gray: #4d4c4f !important;*/
  /*--gray-300: #292929 !important;*/
  --red: #c03a2b !important;
  --green: #016969 !important;
  /*--green: #1f3e35 !important;*/
  --medium-green: #1f3e35 !important;
  --dark-green: #0e1c18 !important;
  --yellow: #ffaa01 !important;
  --light-yellow: #fff3cd !important;
  --primary: #4e73df !important;
  --black: #000000 !important;

  --gray-100: #0c0b10;
  --gray-200: #18181a;
  --gray-300: #29282b;
  --gray-400: #38373a;
  --gray-500: #4d4c4f;
  --gray-600: #959496;
  --gray-700: #b7b7b7;
  --gray-800: #e8e8e8;
  --gray-900: #f7f7f7;

  --gray: var(--gray-700) !important;

  --backgrond: #ffffff;
}

.text-red {
  color: var(--red);
}

.text-yellow {
  color: var(--yellow);
}

.text-green {
  color: var(--green);
}

.hover-link-color {
  color: var(--blue);
  cursor: pointer;
}

.hover-link-color:hover {
  color: var(--dark-blue);
}

.btn.btn-outline-primary {
  color: var(--blue);
  border: 1px solid var(--blue);
}

.btn.btn-outline-primary:hover {
  color: var(--gray-700);
  border: 1px solid var(--blue);
  background-color: var(--blue);
}

tbody {
  background-color: var(--gray-800);
}

.float-right {
  float: right;
}

.form-input {
  border-radius: 10rem;
  font-size: 0.85rem;
}

.header-text {
  color: var(--dark-green);
  font-weight: 700;
  margin: 0;
  font-size: 1rem;
}

.title {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.2;
  padding-left: 1rem;
  margin: 1rem 0;
}

.text {
  font-size: 0.7rem;
  font-weight: 700;
}
.text_lg {
  font-size: 0.9rem;
  font-weight: 700;
}

.card {
  border-radius: 0.5rem !important;
}

.card-grey {
  background-color: var(--gray-900);
  border-radius: 0.5rem;
}

.card_body_scroll {
  overflow: auto;
  height: 30rem;
}

.org-chart-card {
  border-radius: 0.5rem !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}

.custom-select {
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray-700);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  max-width: 100%;
  cursor: pointer;
}

.default-button {
  padding: 0.3rem 0.5rem;
  margin: 0.4rem;

  background-color: transparent;
  border-radius: 0.25rem;
  border: none;
  text-decoration: none;
  color: var(--dark-green);
  border: 1px solid var(--dark-green);
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.default-button.on-bar {
  margin: 0;
  border-radius: 0;
}

.default-button-active {
  color: #fff;
  background-color: var(--green);
  /*border-color: var(--gray-600);*/
}

.default-button-disabled {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.disabled-button {
  padding: 0.3rem 0.5rem;
  margin: 0.4rem;
  margin-bottom: 0.5rem;
  background-color: var(--gray-300);
  border-radius: 0.25rem;
  border: none;
  text-decoration: none;
  color: var(--gray-800);
  border: 1px solid var(--gray-800);
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.tableLink {
  text-decoration: none;
}
.tableLink:hover {
  text-decoration: underline;
}

.default-button:hover {
  color: #fff;
  background-color: var(--medium-green);
  border-color: var(--dark-green);
}

.disabled-button:hover {
  color: "black";
}

.default-button-disabled:hover {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.default-button-red {
  border: 1px solid var(--red);
  color: var(--red);
}

.default-button-red:hover {
  color: #fff;
  background-color: var(--red);
  border-color: var(--red);
}

.default-button-green {
  border: 1px solid var(--green);
  color: var(--green);
}

.default-button-green:hover {
  color: #fff;
  background-color: var(--green);
  border-color: var(--green);
}

.default-button-gray {
  border: 1px solid var(--gray-300);
  color: var(--gray-300);
}

.default-button-gray:hover {
  color: #fff;
  background-color: var(--gray-300);
  border-color: var(--gray-300);
}

.button-text {
  background-color: transparent;
  border: none;
  text-decoration: none;
  color: var(--blue);
  transition: color 0.15s ease-in-out;
  cursor: pointer;
}

.button-text:hover {
  color: var(--dark-blue);
}

.hover-underline:hover {
  text-decoration: underline;
}

.td-small {
  padding: 0.2rem !important;
}

/* Modals */

.modal-title {
  color: var(--gray-300);
  font-size: 1.25rem;
}

.modal-subtitle {
  color: var(--gray-300);
}

.modal-input {
  outline: none;
  padding: 0.37rem 0.75rem;
  border-radius: 0.375rem;
  border: 1px solid lightgray;
  width: 100%;
}

.modal-button-container {
  display: flex;
  justify-content: flex-end;
}

/* Screen sizes */

.desktop-only {
  visibility: hidden;
  max-height: 0;
}

.mobile-only {
  visibility: visible;
  max-height: auto;
}

@media only screen and (min-width: 768px) {
  .desktop-only {
    visibility: visible;
    max-height: auto;
  }
  .mobile-only {
    visibility: hidden;
    max-height: 0;
  }
}

/* Tabs */

.tab-link {
  cursor: pointer;
  padding: 0.75rem;
  border: 1px solid transparent;
  border-bottom-color: #dddfeb;
  border-radius: 0.35rem 0.35rem 0 0;
  font-weight: 400;
  transition: all 0.25s ease-in-out;
}

.tab-link:hover {
  border-right-color: #dddfeb75;
  border-top-color: #dddfeb75;
  border-left-color: #dddfeb75;
}

.tab-link-active {
  border: 1px solid #dddfeb;
  border-bottom-color: transparent;
  background-color: white;
  color: black;
}

/* Transitions */

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: 0.4s ease-out 0s fade-in;
}

.fade-in-long {
  animation: 0.8s ease-out 0s fade-in;
}

/* Accordion */

.accordion-container {
  background-color: #fff;
  border: none;
  border-radius: 0.5rem;
  border: 1px solid var(--blue);
  color: var(--blue);
  box-shadow: 0 0px 1px 1px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  transition: all 0.2s;
  cursor: pointer;
}

.accordion-container:hover {
  box-shadow: 0 0 5px rgba(33, 33, 33, 0.15);
  background-color: var(--light-blue);
}

.accordion-container-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--light-blue);
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.recharts-wrapper svg {
  overflow: inherit !important;
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom: none;
}

thead {
  background-color: var(--gray-800);
  word-break: keep-all;
}

thead.sticky {
  position: sticky;
  top: 0;
}

tbody {
  background-color: transparent;
}

tr {
}

th:last-child {
  border-radius: 0 1rem 1rem 0;
  /*border: 1px solid;*/
}

th:first-child {
  border-radius: 1rem 0 0 1rem;
  /*border: 1px solid;*/
}

.breadCrumb {
  color: var(--green);
  cursor: pointer;
  text-decoration: underline;
}
