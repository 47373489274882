text {
  font-size: 0.6rem;
}

@media only screen and (min-width: 1440px) {
  text {
    font-size: 0.6rem;
  }
}

@media only screen and (min-width: 2000px) {
  text {
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 2560px) {
  text {
    font-size: 1rem;
  }
}
