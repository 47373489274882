.auth-input {
  border: 1px solid #2c5ce5;
  padding: 0.75rem 1rem;
  color: black;
  border-radius: 10px;
  outline: none;
  font-size: 400;
  margin: 1rem 0;
  width: 100%;
}

.auth-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.auth-btn {
  padding: 0.5rem 1.5rem;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.4s ease;
  color: #2c5ce5;
  background-color: white;
  border: 1px solid #2c5ce5;
  margin-top: 1rem;
}

.auth-btn:hover {
  color: white;
  background-color: #2c5ce5;
}

.auth-title {
  color: #2c5ce5;
  font-weight: bold;
  text-align: center;
}

.auth-bg {
  background-color: #f6f6f6;
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-card {
  background-color: white;
  border-radius: 1rem;
  padding: 3rem;
  box-shadow: 0px 5px 9px -5px rgba(0, 0, 0, 0.5);
}
